<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <!--        <v-button text="新增" @click="toAdd"></v-button>-->
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <community-select v-model="searchParam.communityId" label="选择园区" />
        <v-input label="活动名称" v-model="searchParam.activityName" />
        <v-select
          clearable
          :options="auditStatusOptionsAll"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus == 0"
          @click="toAudit(scope.row)"
        />
        <v-button
          text="详情"
          type="text"
          v-if="scope.row.auditStatus != 0"
          @click="toView(scope.row)"
        />
      </template>
    </v-list>

    <v-dialog
      title="审核"
      v-model="auditDialogFormVisible"
      width="50%"
      @confirm="doAudit"
      :showFooterButton="tempObj.isAudit"
    >
      <el-form label-width="100px">
        <el-form-item label="活动标题">
          <v-input v-model="tempObj.activityName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动地址">
          <v-input v-model="tempObj.activityAddress" :width="400" disabled />
        </el-form-item>
        <el-form-item label="封面图片">
          <v-upload :imgUrls.sync="tempObj.activityPicture" disabled />
        </el-form-item>
        <el-form-item label="发布者">
          <v-input v-model="tempObj.publisherName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="发布时间">
          <v-input v-model="tempObj.publishTime" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动报名时间">
          <v-input v-model="tempObj.applyDateText" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动时间">
          <v-input v-model="tempObj.activityDateText" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动图片">
          <v-upload :imgUrls.sync="tempObj.imageUrls" disabled />
        </el-form-item>
        <el-form-item label="活动内容">
          <v-input
            placeholder="请输入活动内容"
            type="textarea"
            :width="400"
            rows="5"
            v-model="tempObj.content"
            disabled
          />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select
            :disabled="!tempObj.isAudit"
            :options="auditStatusOptions"
            v-model="tempObj.auditStatus"
          />
        </el-form-item>
        <el-form-item label="积分规则">
          <v-select
            clearable
            :options="activityPointSettingList"
            v-model="tempObj.pointsSettingId"
          />
        </el-form-item>

        <el-form-item label="奖励积分" v-if="tempObj.auditStatus === '1'">
          <v-input
            v-model="tempObj.bonusPoints"
            type="number"
            :disabled="!tempObj.isAudit"
          />
        </el-form-item>
        <el-form-item label="备注信息">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
            :disabled="!tempObj.isAudit"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  activityApplyList,
  activityAudit,
  activityApplyInfo,
  activityPointSettingList,
} from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "activityAuditList",
  data() {
    return {
      auditDialogFormVisible: false,
      activityPointSettingList: [],
      bstop: 1,
      tempObj: {
        content: "",
        imageUrls: null,
        pointsSettingId: null,
        isAudit: false,
        applyId: null,
        activityName: null,
        activityAddress: null,
        activityPicture: null,
        publisherName: null,
        publishTime: null,
        publisherPic: null,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 10,
        applyDateText: null,
        activityDateText: null,
        scheduleId: null,
      },
      auditStatusOptions: [
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      auditStatusOptionsAll: [
        { value: "0", label: "待审核" },
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],

      searchParam: { communityId: null, activityName: null, auditStatus: null },
      tableUrl: activityApplyList,
      headers: [
        {
          prop: "communityName",
          label: "园区名称",
        },
        // {
        //   prop: "activityId",
        //   label: "活动Id",
        // },
        {
          prop: "activityName",
          label: "活动名称",
        },
        {
          prop: "activityAddress",
          label: "活动地点",
        },
        {
          prop: "activityPicture",
          label: "封面图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "limitCount",
          label: "限制人数",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            for (let i = 0; i < this.auditStatusOptionsAll.length; i++) {
              if (
                row.auditStatus === Number(this.auditStatusOptionsAll[i].value)
              ) {
                return this.auditStatusOptionsAll[i].label;
              }
            }
          },
        },
        {
          prop: "applyStartDate",
          label: "报名开始时间",
        },
        {
          prop: "applyEndDate",
          label: "报名结束时间",
        },
        {
          prop: "startDate",
          label: "活动开始时间",
        },
        {
          prop: "endDate",
          label: "活动结束时间",
        },
        {
          prop: "publisherName",
          label: "发布者",
        },
      ],
    };
  },
  components: {},
  mounted() {
    this.getActivityPointSettingList();
  },
  computed: {},
  methods: {
    async getActivityPointSettingList() {
      this.activityPointSettingList = [];
      let res = await this.$axios.get(`${activityPointSettingList}`);
      if (res.code === 200) {
        if (res.data) {
          res.data.forEach((item) => {
            this.activityPointSettingList.push({
              value: item.settingId.toString(),
              label: item.settingName,
            });
          });
        }
      }
    },
    requestApplyInfo(id, showAudit) {
      this.$axios
        .get(`${activityApplyInfo}`, {
          params: {
            applyId: id,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            let data = res.data;
            this.tempObj = {
              isAudit: showAudit,
              applyId: data.applyId,
              activityName: data.activityName,
              activityAddress: data.activityAddress,
              activityPicture: data.activityPicture,
              publisherName: data.publisherName,
              publishTime: data.publishTime,
              publisherPic: data.publisherPic,
              auditStatus: null,
              auditRemarks: null,
              content: data.content,
              imageUrls: data.imageUrls,
              bonusPoints: 10,
              applyDateText: data.applyStartDate + " - " + data.applyEndDate,
              activityDateText: data.startDate + " - " + data.endDate,
              scheduleId: data.scheduleList[0] ? data.scheduleList[0].id : "",
            };

            if (!showAudit) {
              this.tempObj.auditStatus = data.auditStatus.toString();
              this.tempObj.auditRemarks = data.auditRemarks;
              this.tempObj.bonusPoints = data.bonusPoints;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    toAudit(data) {
      this.tempObj = {
        pointsSettingId: null,
        isAudit: false,
        applyId: null,
        activityName: null,
        activityAddress: null,
        activityPicture: null,
        publisherName: null,
        publishTime: null,
        publisherPic: null,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 10,
        applyDateText: null,
        activityDateText: null,
        scheduleId: null,
      };
      this.auditDialogFormVisible = true;
      this.requestApplyInfo(data.applyId, true);
    },
    toView(data) {
      this.auditDialogFormVisible = true;
      this.requestApplyInfo(data.applyId, false);
    },

    doAudit() {
      if (this.bstop != 1) {
        return;
      }
      this.bstop = 0;
      let _this = this;
      if (!this.tempObj.auditStatus) {
        this.$message.error("请选择审核结果！");
        return;
      }
      if (this.tempObj.bonusPoints && this.tempObj.bonusPoints < 0) {
        this.$message.error("奖励积分不能小于0！");
        return;
      }
      this.$axios
        .post(`${activityAudit}`, null, {
          params: {
            userId: this.$store.state.app.userInfo.userId,
            applyId: this.tempObj.applyId,
            auditStatus: this.tempObj.auditStatus,
            auditRemarks: this.tempObj.auditRemarks,
            bonusPoints: this.tempObj.bonusPoints,
            pointsSettingId: _this.tempObj.pointsSettingId,
            scheduleId: _this.tempObj.scheduleId,
            // pointSettingList: JSON.stringify([
            //   {
            //     id: _this.tempObj.applyId,
            //     pointsSettingId: _this.tempObj.pointsSettingId,
            //   },
            // ]),
          },
        })
        .then((res) => {
          this.bstop = 1;
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
}
</style>
